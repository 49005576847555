header{
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container{
  text-align: center;
  height: 100%;
  position: relative;
}

/* CTA */

.cta{
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/* SOCIALS */

.header__socials{
  display: flex;
  margin-top: 2.5rem;
  gap: 1rem;
  justify-content: center;
  font-size: 1.5rem;
}

/* .header__socials::after{
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
} */


/* ME */
.me{
  width: 22rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
}

/* SCROLL DOWN */

.scroll__down {
  position: absolute;
  right: 0rem;
  bottom: 10rem;
  /* transform: rotate(90deg); */
  font-weight: 200;
  font-size: 2.5rem;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
  .header{
    height: 100vh;
  }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
  .header{
    height: 100vh;
  }

  /* .header__socials, .scroll__down{
    display: none;
  } */
}
